.logo-container {
  color: #06f;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  cursor: pointer;
  span {
    font-size: 25px;
  }
  label {
    cursor: pointer;
  }
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  .nav-link {
    color: black;
    font-size: 15px;
  }
  .active {
    color: #06f;
  }
  .menu-icon {
    display: none;
  }

  @media (max-width: 768px) {
    .menu-links {
      display: none !important;
    }
    .menu-icon {
      display: block !important;
    }
    .menu-links-mobile {
      height: 200px;
      background-color: rgb(109, 109, 109) !important;
      // position: absolute;
      z-index: 1;
      top: 0;
      a {
        color: black !important;
      }
    }
  }
}

.m-body {
  background-color: #e5e7eb38;
}
