.text-main {
  font-size: 60px;
  font-weight: bold;
  padding: 70px 200px;
  text-align: center;
  line-height: normal;
  color: rgb(0, 0, 0);

  @media (max-width: 768px) {
    font-size: 30px !important;
    padding: 20px !important;
  }
}

.palettes-container{
  width: 100%;
  
}