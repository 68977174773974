.analyser-box {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 60%;
  height: 500px;
  margin: 0 auto;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto !important;
    .pal-card-info * {
      width: 100% !important;
      margin: 2px !important;
    }
    .color-wrapper{
      flex-wrap: wrap !important;
    }
    .c-box-o{
      width: 96% !important;
    }
  }
  .img-box {
    display: inline-block;
    width: 100%;
    max-width: 600px;
    height: 200px;
    margin-top: 5px;
  }
  .img-info-box {
    width: 100%;
    input {
      width: 92%;
      margin: 0 10px
    }
  }
}
