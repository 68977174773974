.pal-card {
  width: 380px;
  height: 155px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  background-color: #fff;
  margin: 20px;
  .c-box {
    height: 60px;
    width: 80px;
    border-radius: 100px;
    margin: 5px;
  }
  .c-box:hover {
    transform: scale(1.2);
  }
  .color-picker {
    position: absolute;
    margin-top: 100px;
  }

  .action-container {
    display: flex;
    flex-direction: row;
    font-size: medium;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e6e6e6;
    margin-top: 10px;

    span {
      padding: 10px;
    }
    span:hover {
      cursor: pointer;
      color: #00a8ff;
    }
  }
}

.grad-card {
  width: 210px;
  height: 250px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  background-color: #fff;
  margin: 20px;
  @media (max-width: 768px) {
    width: 80%;
    height: 300px;
  }
   .bg-container{
     height: 81%;
     border-radius: 6px 6px 0 0;
   }

  .action-container {
    display: flex;
    flex-direction: row;
    font-size: medium;
    align-items: center;
    justify-content: center;


    span {
      padding: 10px;
    }
    span:hover {
      cursor: pointer;
      color: #00a8ff;
    }
  }
}


.sub-text{
  text-align: center;
  color: #8b8b8b;
  font-size: 19px;
}

.m-container{
  padding: 40px;
}

.btn-link{
  border: 1px solid #00a8ff;
  border-radius: 5px;
}
