.text-main {
  font-size: 60px;
  font-weight: bold;
  padding: 70px 200px;
  text-align: center;
  line-height: normal;
  color: rgb(0, 0, 0);
}

.palettes-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}